<template>
  <div class="Tracking">
    <div class="TrackingLeft">
      <div class="TrackingLeftSearch">
        <el-tooltip
          class="item"
          effect="dark"
          :content="mapShow ? 'Hide map' : 'Show map'"
          placement="bottom-start"
        >
          <div
            class="trackingIcon"
            @click="mapShow = !mapShow"
            :class="{ showMap: mapShow }"
          >
            <svg-icon icon-class="tracking"></svg-icon>
          </div>
        </el-tooltip>

        <el-input
          class="searchInput"
          v-model="searchInput"
          @keyup.enter.native="TrackClick()"
        ></el-input>
        <dw-button type="primary" class="trackButton" @click="TrackClick()"
          >{{$t('lang.NText151')}}</dw-button
        >
      </div>
      <div class="input" v-if="resultInfo">
        <el-cascader
          v-model="cascaderVal"
          :options="options"
          clearable
          @change="changeUserList"
          :placeholder="
            options && options.length > 0 ? $t('lang.BText80') : $t('lang.NText136')
          "
        >
          <template slot-scope="{ node, data }">
            <div class="line1">{{ data.label }}</div>
          </template>
        </el-cascader>
        <p class="Results">
          <span>{{ childrenLength }}</span>
          <span>{{$t('lang.NText152')}}</span>
        </p>
      </div>

      <div class="map" v-if="resultInfo" :class="{ mapClass: !mapShow }">
        <div class="mapback" v-if="mapShow"></div>
        <div class="Progress" v-show="mapShow">
          <div
            class="step"
            v-for="(item, index) in trackingObj.currList"
            :key="index"
          >
            <div
              class="step-main"
              @click="clickSpot(item, index)"
              :class="{
                locationText: item.state == '2' && item.currState == '2',
              }"
            >
              <p>{{ item.tile }}</p>
              <p>{{ item.location }}</p>
            </div>
            <div class="step-head">
              <div
                class="step-head-line"
                :class="{
                  isStateCurr: item.state == '2' || item.currState == '2',
                }"
              ></div>
              <div
                class="step-head-icon"
                @click="clickSpot(item, index)"
                :class="{
                  isCurr: item.state == '2' && item.currState == '1',
                  location: item.state == '2' && item.currState == '2',
                }"
              >
                <!-- <img src="../assets/image/Container.svg" class="icon-img" v-if="item.state == '2' && item.currState == '2' && item.ischecked" alt="" > -->
                <span class="icon-spot">
                  {{ index + 1 }}
                </span>
              </div>
              <!-- <div v-if="item.ischecked" class="halfPro">
                        <div class="halfProBox" :class="{'isStateCurr': (item.state == '2')}">
                            <img src="../assets/image/Container.svg">
                        </div>
                    </div> -->
            </div>
          </div>
        </div>
        <mapbox-gl
          v-if="!mapShow"
          ref="mapboxGL"
          :isMap="!mapShow ? true : false"
          :currList="trackingObj.currList"
        ></mapbox-gl>
        <div class="arrowMap" v-if="!mapShow">
          <div @click="leftClick()">
            <svg-icon icon-class="arror_left_2" class="leftClick"></svg-icon>
          </div>
          <div @click="rightClick()">
            <svg-icon icon-class="into" class="rightClick"></svg-icon>
          </div>
        </div>
        <div class="CloseMap" v-if="!mapShow" @click="mapShow = !mapShow">
          <svg-icon icon-class="close"></svg-icon>
        </div>

        <div class="maskMap" v-if="!mapShow">
          <div class="SQIN">{{ trackingObj.HBLNO }}</div>
          <div class="from">
            <div class="in inaction">
              <div>{{$t('lang.QText13')}}</div>
              <div>{{ trackingObj.fromLocation }}</div>
            </div>
            <div class="in inline">--</div>
            <div class="in inlast">
              <div>{{$t('lang.QText14')}}</div>
              <div>{{ trackingObj.toLocation }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="fromDiv" v-show="!mapShow && resultInfo">
        <div class="SQIN">{{ trackingObj.HBLNO }}</div>
        <div class="fromTo">
          <div>
            <div class="from-title">{{$t('lang.QText13')}}</div>
            <div>{{ trackingObj.fromLocation }}</div>
          </div>
          <div class="to">--</div>
          <div>
            <div class="from-title">{{$t('lang.QText14')}}</div>
            <div>{{ trackingObj.toLocation }}</div>
          </div>
        </div>
      </div>
      <div class="current" v-if="resultInfo">
        <div class="left">{{$t('lang.NText135')}}</div>
        <div class="right">
          <div class="rightTitle">{{ trackingObj.CurrentLocation }}</div>
          <div class="address">{{ trackingObj.CurrentExplain }}</div>
          <div class="time">
            {{ trackingObj.CurrentTime.replace("T", " ").split(" ")[0] }}
          </div>
        </div>
      </div>
      <div class="Estimate" v-if="trackingObj.EsLocation">
        <div class="left">{{$t('lang.NText153')}}</div>
        <div class="right">
          <div class="rightTitle">{{ trackingObj.EsLocation }}</div>
          <div class="address">
            {{ trackingObj.EsExplain }}
          </div>
          <div class="time">
            {{
              trackingObj.EsTime
                ? trackingObj.EsTime.replace("T", " ").split(" ")[0]
                : ""
            }}
          </div>
        </div>
      </div>
      <div class="TrackingList" v-if="resultInfo">
        <!-- <div
          class="line1"
          v-if="trackingObj.nodeListShow && trackingObj.nodeListShow.length > 0"
        ></div> -->
        <div
          class="listData"
          v-if="trackingObj.nodeListShow && trackingObj.nodeListShow.length > 0"
        >
          <div
            class="listItem"
            v-for="(item, index) in trackingObj.nodeListShow"
            :key="index"
            :class="{
              status1: item.state == '1',
              status2: item.currState == '2',
              status3: item.state == '2',
            }"
          >
            <div class="listItemLeft">
              <div
                class="line2"
                v-show="index < trackingObj.nodeListShow.length - 1"
              ></div>
              <div class="icon">
                <svg-icon
                  icon-class="FCL2"
                  v-show="item.iconindex == '1' && item.isSHow"
                ></svg-icon>
                <svg-icon
                  icon-class="oceanFreight2"
                  v-show="item.iconindex == '2' && item.isSHow"
                ></svg-icon>
                <svg-icon
                  icon-class="train"
                  v-show="item.iconindex == '3' && item.isSHow"
                ></svg-icon>
                <svg-icon
                  icon-class="trucks"
                  v-show="item.iconindex == '4' && item.isSHow"
                ></svg-icon>
              </div>
            </div>
            <div class="listItemRight">
              <div class="rightTitle">{{ item.eventName }}</div>
              <div class="address">
                {{ item.location }}
              </div>
              <div class="time">
                {{ item.eventTime.replace("T", " ").split(" ")[0] }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="NoData">
          <img src="../../assets/images/empty_img.png" alt="" />
          <p>{{$t('lang.NText136')}}</p>
        </div>
      </div>
    </div>
    <div class="TrackingRight" v-if="resultInfo">
      <k-second-header
        :activeIndex="'2'"
        :data="quotationMenu"
        :clickchange="false"
        @handleSelect="handleSelect"
      ></k-second-header>
      <div class="bookingReviewMainTitle" v-show="tab == 1">
        <div class="Ocean-freight-FCL">
          <div class="icon-div">
            <svg-icon icon-class="oceanFreight"></svg-icon>
            <div class="icon-line"></div>
            <svg-icon icon-class="FCL"></svg-icon>
          </div>
          <div class="title">
            Ocean freight <br />
            FCL
          </div>
        </div>
        <div class="shippingAuthorization" v-if="tab == 2">
          <div class="text">{{$t('lang.BText122')}}</div>
        </div>
        <div
          class="shippingAuthorization"
          :class="{ unClick: !hasQuotation }"
          v-if="tab == 1"
        >
          <div class="text">{{$t('lang.BText65')}}</div>
        </div>
      </div>
      <div class="topData" v-show="tab == 2" v-loading="loading">
        <div class="topDataLeft">
          <div class="Ocean-freight-FCL">
            <div class="icon-div">
              <svg-icon icon-class="oceanFreight"></svg-icon>
              <div class="icon-line"></div>
              <svg-icon icon-class="FCL"></svg-icon>
            </div>
            <div class="title">
              Ocean freight <br />
              FCL
            </div>
          </div>
          <div class="topDataTitle">{{ shipmentTitle }}</div>
        </div>
        <div class="topDataRight">
          <div class="mainData">
            <div class="mainData-title">MBL No.:</div>
            <div class="mainData-value">{{ shipmentView.mblNo }}</div>
          </div>
          <div class="mainData">
            <div class="mainData-title">Carrier:</div>
            <div class="mainData-value">{{ shipmentView.carrier }}</div>
          </div>
          <div class="mainData">
            <div class="mainData-title">Vessel & Voyage:</div>
            <div class="mainData-value">
              {{ shipmentView.vessel }} {{ shipmentView.voyage }}
            </div>
          </div>
          <div class="mainData">
            <div class="mainData-title">HBL No.:</div>
            <div class="mainData-value">{{ shipmentView.hblNo }}</div>
          </div>
          <div class="mainData">
            <div class="mainData-title">Container:</div>
            <div class="mainData-value">{{ shipmentView.container }}</div>
          </div>
          <div class="mainData">
            <div class="mainData-title">Incoterm:</div>
            <div class="mainData-value">{{ shipmentView.incoterm }}</div>
          </div>
        </div>
      </div>

      <quotation-view v-show="tab == 1"></quotation-view>
      <!-- <booking-review v-show="tab == 2" :showButton="false"></booking-review> -->
      <basic-info
        v-if="tab == 2 && getShipmentDataState"
        :shipmentData="shipmentView"
      ></basic-info>
      <dw-steps
        v-if="tab == 0"
        :stepsData="stepsData"
        class="dwSteps"
      ></dw-steps>
    </div>
    <div
      v-if="Datanotfound"
      style="float: left; width: 100%; margin-top: -40px"
    >
      <p style="text-align: center; font-size: 37px">{{$t('lang.NText136')}}</p>
      <img
        src="../../assets/images/Datanotfound.svg"
        alt=""
        style="width: 20%; margin-left: 40%"
      />
    </div>
    <div v-if="trackingBg" style="float: left; width: 100%">
      <img
        src="../../assets/images/tracking.svg"
        alt=""
        style="width: 25%; margin-left: 37.5%"
      />
    </div>
  </div>
</template>

<script>
import KSecondHeader from "../../components/kHeader/kSecondHeader.vue";
import quotationView from "../Quotation/quotationView.vue";
import bookingReview from "../Booking/review.vue";
import dwSteps from "../../components/dwSteps/dwSteps.vue";
import test from "../../test/test";
import mapboxGl from "../../components/map/mapboxGl";
import basicInfo from "../Shipment/basicInfo.vue";
export default {
  components: {
    KSecondHeader,
    quotationView,
    bookingReview,
    dwSteps,
    mapboxGl,
    basicInfo,
  },
  data() {
    return {
      resultInfo: false,
      trackingBg: true,
      Datanotfound: false,
      loading: false,
      hasQuotation: false,
      getShipmentDataState: false,
      searchInput: "",
      // searchInput: "ONEYPNHA01488300",
      // searchInput: 'SJKTS0001736',
      // searchInput: '581923876',
      trackingArr: [],
      childrenLength: 0,
      trackingObj: {
        HBLNO: "",
        fromLocation: "",
        toLocation: "",
        CurrentLocation: "",
        CurrentExplain: "",
        CurrentTime: "",
        EsLocation: "",
        EsExplain: "",
        EsTime: "",
        currList: [],
        nodeListShow: [],
        containerNumberL: "",
      },
      cascaderVal: [],
      quotationMenu: [
        {
          menuName: "Task",
          extend: {
            extendS3: "Task",
          },
        },
        {
          menuName: "Quotation",
          extend: {
            extendS3: "Quotation",
          },
        },
        {
          menuName: "Shipment",
          extend: {
            extendS3: "Shipment",
          },
        },
        // {
        //   menuName: "Chatting history",
        // },
      ],
      tab: "0",
      stepsData: [
        {
          time: "Dec 7th,2021 2:32 pm",
          user: "Grey Chen",
          content: "Submit Booking, BID: XXXXXXXX",
        },
        {
          time: "Dec 7th,2021 5:30 pm",
          user: "Terry",
          content: "Created pricing, QID: XXXXXXXX",
        },
        {
          time: "Dec 8th,2021 10:12 am",
          user: "Grey Chen",
          content: "Pricing confirmed",
        },
        {
          time: "Dec 8th,2021 10:22 am",
          user: "Grey Chen",
          content: "Submit Booking, BID: XXXXXXXX",
        },
        {
          time: "Dec 7th,2021 5:30 pm",
          user: "Terry",
          content: "Submit Booking, BID: XXXXXXXX",
        },
      ],
      TrackingList: test.trackingList,
      mapShow: true,
      userList: [],
      options: [],
      shipmentView: {},
      ProgressData: [
        {
          eventName: "Pick up",
          location: "Quarry Bay,CN",
          state: "2",
          currState: "1",
          ischecked: false,
        },
        {
          eventName: "Departed origin port",
          location: "IN TERMINAL",
          state: "2",
          currState: "1",
          ischecked: false,
        },
        {
          eventName: "Arrived discharge port",
          location: "Long Beach,US",
          state: "2",
          currState: "2",
          ischecked: true,
        },
        {
          eventName: "Delivered",
          location: "Quarry Bay,CN",
          state: "1",
          currState: "1",
          ischecked: false,
        },
      ],
    };
  },
  methods: {
    leftClick() {
      this.$refs.mapboxGL.moveSpot("left");
    },
    rightClick() {
      this.$refs.mapboxGL.moveSpot("right");
    },
    clickSpot(row, index) {
      this.mapShow = false;
      this.$nextTick(() => {
        this.$refs.mapboxGL.initmap(row, index, true);
      });
    },
    TrackClick() {
      if (!this.searchInput) return;
      // this.$notify({
      //     title: '请',
      //     type: "error",
      //     offset: 100,
      // });
      this.trackingObj.containerNumberL = "";
      this.$store.commit("api/SET_LOADING", true);
      this.$ajax
        .cPost(this.source.tracking_api.queryTracking, {
          selectNo: this.searchInput,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.trackingBg = false;
            this.resultInfo = true;
            this.Datanotfound = false;
            this.options = [];
            this.cascaderVal = [];
            this.trackingArr = res.data.tracking[0]["trackingSub"];
            if (res.data.tracking.length > 0) {
              res.data.tracking[0]["trackingSub"].forEach((req, index) => {
                if (index == 0) this.cascaderVal.push(req.HBLNO);
                let obj = {
                  value: "",
                  label: "",
                  children: [],
                };
                obj.value = req.HBLNO;
                obj.label = req.HBLNO;
                req.containerNumberListMap.forEach((el, index) => {
                  if (index == 0) this.cascaderVal.push(el.containerNumberL);
                  let elObj = {
                    value: "",
                    label: "",
                    nodeListShow: [],
                  };
                  elObj.value = el.containerNumberL;
                  elObj.label = el.containerNumberL;
                  this.childrenLength = this.childrenLength + 1;
                  obj.children.push(elObj);
                });
                this.options.push(obj);
              });
            }
            this.$store.commit("api/SET_LOADING", false);
            this.Data(this.cascaderVal);
            this.getShipmentData(this.cascaderVal);
            this.tab = "2";
          } else {
            this.$store.commit("api/SET_LOADING", false);
            this.trackingBg = false;
            this.resultInfo = false;
            this.Datanotfound = true;
          }
        });
    },
    Data(el) {
      if (el.length == 0) {
        this.trackingBg = true;
        this.resultInfo = false;
        this.trackingObj = {
          HBLNO: "",
          fromLocation: "",
          toLocation: "",
          CurrentLocation: "",
          CurrentExplain: "",
          CurrentTime: "",
          EsLocation: "",
          EsExplain: "",
          EsTime: "",
          currList: [],
          nodeListShow: [],
          containerNumberL: "",
        };
        return;
      }
      let resObj = this.trackingArr.find((res) => res.HBLNO == el[0]);
      let reqObj = resObj.containerNumberListMap.find(
        (req) => req.containerNumberL == el[1]
      );
      this.trackingObj.HBLNO = resObj.HBLNO;
      this.trackingObj.fromLocation = resObj.fromLocation;
      this.trackingObj.toLocation = resObj.toLocation;
      this.trackingObj.CurrentLocation =
        reqObj.currentNewStatus.split(" , ")[0];
      this.trackingObj.CurrentExplain = reqObj.currentNewStatus.split(" , ")[1];
      this.trackingObj.CurrentTime = reqObj.currentTime;
      this.trackingObj.EsLocation = reqObj.currentNewStatus_next
        ? reqObj.currentNewStatus_next.split(" , ")[0]
        : "";
      this.trackingObj.EsExplain = reqObj.currentNewStatus_next
        ? reqObj.currentNewStatus_next.split(" , ")[1]
        : "";
      this.trackingObj.EsTime = reqObj.currentTime_next;
      this.trackingObj.currList = reqObj.currList;
      this.trackingObj.nodeListShow = this.nodeListShowFun(reqObj.nodeListShow);
      this.trackingObj.containerNumberL = reqObj.containerNumberL;
    },
    nodeListShowFun(nodeListShow) {
      let arr = JSON.parse(JSON.stringify(nodeListShow)).reverse();
      let iconindex = "";
      arr.forEach((res, index) => {
        if (index == 0) {
          iconindex = res.iconindex;
          res.isSHow = true;
        } else {
          if (iconindex == res.iconindex) {
            res.isSHow = false;
          } else {
            arr[index - 1].isSHow = true;
            iconindex = res.iconindex;
            res.isSHow = true;
          }
        }
        if (index == arr.length - 1) {
          res.isSHow = true;
        }
      });
      return arr;
    },
    handleSelect(tab) {
      this.tab = tab;
      if (tab == "2") {
        this.getShipmentData(this.cascaderVal);
      } else {
        this.getShipmentDataState = false;
      }
    },
    changeUserList(e) {
      this.Data(e);
    },
    getShipmentData(data) {
      this.loading = true;
      this.$ajax
        .cPost(this.source.shipment_api.view, {
          shipmentNo: data[0],
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.shipmentView = res.data;
            this.$store.commit("api/SET_SHIPMENT", this.shipmentView);
            this.getShipmentDataState = true;
            this.loading = false;
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.Tracking {
  display: flex;
  flex-direction: center;
  flex-wrap: wrap;
  text-align: left;
  height: auto;
  // margin: 0 -20px;
  // justify-content: space-between;
  .TrackingLeft {
    min-width: 900px; /* no*/
    // margin: 0 auto;
    padding-bottom: 100px;
    position: relative;
    height: auto;
    .TrackingLeftSearch {
      display: flex;
      margin-bottom: 20px;
      .trackingIcon {
        padding: 14px 18px;
        border: 2px solid $mainColor;
        margin-right: -2px;
        background-color: $mainColor;
        cursor: pointer;

        .svg-icon {
          color: #fff;
          fill: #fff;
          stroke: #fff;
          height: 24px;
          width: 24px;
        }
      }
      .showMap {
        padding: 14px 18px;
        border: 2px solid $mainColor;
        margin-right: -2px;
        background-color: #fff;
        cursor: pointer;
        .svg-icon {
          fill: $mainColor;
          stroke: $mainColor;
          color: $mainColor;
          height: 24px;
          width: 24px;
        }
      }

      .el-input__inner {
        height: 64px;
        border: 2px solid $mainColor;
      }
      .trackButton {
        // margin-left: 20px;
      }
    }
    .input {
      .el-cascader {
        .el-input {
          width: 360px;
        }
      }
      .Results {
        display: inline-block;
        margin-left: 30px;
        color: #999999;
        font-weight: bold;
        span:nth-child(1) {
          color: #299be4;
          margin-right: 10px;
        }
      }
    }
    .mapClass {
      margin-top: 15px !important;
    }
    .map {
      position: relative;
      height: auto;
      margin-bottom: 20px;
      margin-top: 40px;
      box-sizing: border-box;
      padding-left: 10px;
      .mapback {
        width: 100%;
        height: 161px;
        background-color: #f9f9f9;
        position: absolute;
        top: -103px;
        left: 0;
      }
      .CloseMap {
        position: absolute;
        top: 0;
        right: 0px;
        width: 42px;
        height: 42px;
        background-color: rgba(255, 255, 255, 0.7);
        cursor: pointer;
        .svg-icon {
          color: #999999;
          fill: #999999;
          stroke: #999999;
          transition: 0.5s;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
        &:hover {
          .svg-icon {
            color: #4e5c8f;
            fill: #4e5c8f;
            stroke: #4e5c8f;
          }
        }
      }
      .arrowMap {
        position: absolute;
        top: 0;
        left: 0px;
        width: 80px;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.7);
        div {
          width: 50%;
          height: 100%;
          text-align: center;
          line-height: 25px;
          font-size: 22px;
          cursor: pointer;
          transition: 0.5s;
          display: inline-block;
          .svg-icon {
            color: #999999;
            fill: #999999;
            stroke: #999999;
            transition: 0.5s;
          }
          &:hover {
            .svg-icon {
              color: #132569;
              fill: #132569;
              stroke: #132569;
            }
          }
        }
      }
      .Progress {
        width: 100%;
        height: auto;
        margin-top: 120px;
        .step {
          width: 220px;
          height: 50px;
          display: inline-block;
          position: relative;
          .step-head {
            white-space: normal;
            position: relative;
            text-align: left;
            height: 100%;
            .step-head-line {
              position: absolute;
              border-color: inherit;
              background-color: transparent;
              border-bottom: 2px dashed #cacac7;
              height: 1px;
              top: 9px;
              left: 0;
              right: 0;
            }
            .isStateCurr {
              border-bottom: 2px solid #3f536a;
            }
            .step-head-icon {
              width: 50px;
              height: 50px;
              line-height: 50px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              background: #cccccc;
              box-sizing: border-box;
              border-color: inherit;
              position: absolute;
              top: -15px;
              z-index: 99;
              // left: 0;
              right: 0;
              border: 2px solid #cccccc;
              cursor: pointer;
              .icon-spot {
                text-align: center;
                color: #ffffff;
                font-size: 26px;
              }
              .icon-img {
                width: 24px;
              }
            }
            .halfPro {
              position: absolute;
              width: 110px;
              height: 2px;
              background-color: #3f536a;
              top: 50%;
              transform: translateY(-50%);
              .halfProBox {
                width: 40px;
                height: 40px;
                line-height: 40px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background: #3f536a;
                box-sizing: border-box;
                border-radius: 50%;
                border-color: inherit;
                position: absolute;
                top: -18px;
                z-index: 99;
                right: -10px;
                img {
                  width: 24px;
                }
              }
            }
            .isComplete {
              width: 40px;
              height: 40px;
              top: -9px;
              right: -12px;
              background: #3f536a;
            }
            .isCurr {
              background: #fff;
              border: 2px solid #132569;
              .icon-spot {
                color: #132569 !important;
              }
            }
            .location {
              background: #299be4;
              border: 2px solid #299be4;
              .icon-spot {
                color: #fff !important;
              }
            }
          }
          .step-main {
            text-align: left;
            letter-spacing: 0.03em;
            color: #132569;
            font-size: 18px;
            position: absolute;
            top: -90px;
            right: -150px;
            width: 200px;
            height: 80px;
            word-wrap: break-word;
            white-space: normal;
            word-break: break-all;
            font-weight: bold;
            p {
              margin: 10px 0;
            }
          }
          .locationText {
            color: #299be4;
          }
          &:first-child {
            width: 50px;
            .step-head {
              .step-head-line {
                height: 0;
              }
            }
            .step-main {
              left: 0px;
            }
          }
        }
      }
      .maskMap {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 60px;
        background-color: rgba(255, 255, 255, 0.7);
        .SQIN,
        .from,
        .in {
          display: inline-block;
        }
        .SQIN {
          font-size: 20px;
          color: $mainColor;
          width: 200px;
          height: 60px;
          box-sizing: border-box;
          padding-top: 20px;
          padding-left: 20px;
        }
        .from {
          vertical-align: top;
          color: $mainColor;
          height: 60px;
          width: 500px;
          float: right;
          text-align: right;
          box-sizing: border-box;
          padding-top: 10px;
          .from-title {
            text-align: left;
            font-size: 15px;
          }
          .to {
            margin: 15px 10px 0 10px;
          }
        }
        .in {
          text-align: left !important;
        }
        .inlast {
          margin-right: 20px;
        }
        .inline {
          margin: 0 20px;
        }
        .inaction,
        .inlast {
          div {
            &:nth-child(1) {
              font-size: 16px;
            }
          }
        }
      }
    }
    .fromDiv {
      display: flex;
      color: $mainColor;
      justify-content: space-between;
      border-bottom: 4px solid $mainColor;
      margin-bottom: 20px;
      padding: 20px 0;
      .SQIN {
        font-size: 30px;
      }
      .fromTo {
        display: flex;
        .from-title {
          font-size: 15px;
        }
        .to {
          margin: 15px 10px 0 10px;
        }
      }
    }

    .current,
    .Estimate {
      display: flex;
      padding: 5px 10px;
      .left {
        font-size: 18px;
        color: $fontColor2;
        width: 100px;
        box-sizing: border-box;
        padding-top: 9px;
      }
      .right {
        .rightTitle {
          font-size: 30px;
        }
        .address {
          font-size: 18px;
          line-height: 30px;
        }
        .time {
          font-size: 15px;
          color: $fontColor2;
        }
      }
    }
    .current {
      color: $background2;
    }
    .Estimate {
      color: $fontColor3;
    }
    .TrackingList {
      background-color: $background1;
      border-top: 4px solid $mainColor;
      display: flex;
      width: 100%;
      margin-top: 20px;
      padding-bottom: 20px;
      .line1 {
        margin-top: 20px;
        margin-left: 45px;
        border-left: 4px dashed $mainColor;
        margin-bottom: 20px;
      }

      .listData {
        // margin-left: -48px;
        width: 100%;
      }

      .listItem {
        display: flex;
        width: 100%;
        .listItemLeft {
          position: relative;
          height: 100px;
          width: 100px;
          .icon {
            margin: 25px 18px;
            position: absolute;
            height: 50px;
            left: 10px;
            .svg-icon {
              height: 25px;
              width: 25px;
              color: #fff;
              fill: #fff;
              stroke: #fff;
              padding: 12.5px;
              background-color: $mainColor;
            }
          }
          .line2 {
            position: absolute;
            height: 100px;
            left: 50px;
            top: 50px;
            border-left: 4px dashed $fontColor3;
          }
        }
        .listItemRight {
          background-color: #fff;
          margin-top: 10px;
          width: 85%;
          padding: 10px;
          .rightTitle {
            font-size: 20px;
            color: $mainColor;
          }
          .address {
            font-size: 14px;
            line-height: 25px;
          }
          .time {
            font-size: 14px;
          }
        }
      }
      .status1 {
        .listItemLeft {
          .icon {
            .svg-icon {
              background-color: $fontColor3;
            }
          }
        }
        .listItemRight {
          .rightTitle {
            color: $fontColor3;
          }
        }
      }

      .status3 {
        .listItemLeft {
          .icon {
            .svg-icon {
              background-color: $mainColor;
            }
          }
          .line2 {
            border-left: 4px solid $mainColor;
          }
        }
        .listItemRight {
          .rightTitle {
            color: $mainColor;
          }
        }
      }
      .status2 {
        .listItemLeft {
          .icon {
            .svg-icon {
              background-color: $background2;
            }
          }
        }
        .listItemRight {
          .rightTitle {
            color: $background2;
          }
        }
      }
      .NoData {
        margin: 0 auto;
        img {
          margin-top: 70px;
          width: 250px;
        }
        p {
          text-align: center;
          font-size: 16px;
        }
      }
    }
  }
  .TrackingRight {
    min-width: 900px; /* no*/
    margin: 0 auto;
  }
  .bookingReviewMainTitle {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 4px solid $mainColor;
    padding-bottom: 20px;
    margin-bottom: 20px;
    padding-top: 20px;

    .Ocean-freight-FCL {
      height: 60px;
      display: flex;
      .icon-div {
        height: 60px;
        width: 120px;
        display: flex;
        align-items: center;
        border: 2px solid $mainColor;
        .svg-icon {
          height: 30px;
          width: 30px;
          padding: 0 15px;
          fill: $mainColor;
          stroke: $mainColor;
        }
      }
      .icon-line {
        width: 1px;
        height: 30px;
        background: $mainColor;
      }
      .title {
        margin-left: 20px;
        font-size: 30px;
        color: $mainColor;
        font-weight: bold;
        line-height: 34px;
        text-align: left;
      }
    }
    .shippingAuthorization {
      height: 60px;
      width: 200px;
      line-height: 20px;
      text-align: center;
      background-color: $mainColor;
      color: #fff;
      font-size: 18px;
      word-break: normal;
      cursor: pointer;
      display: table;
      .text {
        display: table-cell;
        vertical-align: middle;
      }
    }
    .unClick {
      background-color: $borderColor1;
      color: $fontColor2;
    }
  }
  .topData {
    display: flex;
    border-bottom: 2px solid $mainColor;
    padding: 20px 0;
    .topDataLeft {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .topDataTitle {
        font-size: 60px;
        color: $borderColor1;
        text-align: left;
      }
    }
    .mainData {
      display: flex;
      font-size: 20px;
      line-height: 30px;
      .mainData-title {
        color: $fontColor3;
        width: 180px;
        text-align: right;
        margin-right: 10px;
      }
      .mainData-value {
        color: $mainColor;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 315px;
        text-align: left;
      }
    }
  }
  .Ocean-freight-FCL {
    height: 60px;
    display: flex;
    .icon-div {
      height: 60px;
      width: 120px;
      display: flex;
      align-items: center;
      border: 2px solid $mainColor;
      .svg-icon {
        height: 30px;
        width: 30px;
        padding: 0 15px;
        fill: $mainColor;
        stroke: $mainColor;
      }
    }
    .icon-line {
      width: 1px;
      height: 30px;
      background: $mainColor;
    }
    .title {
      margin-left: 20px;
      font-size: 30px;
      color: $mainColor;
      font-weight: bold;
      line-height: 34px;
      text-align: left;
      width: 210px;
    }
  }
}
</style>